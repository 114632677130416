<template>
  <UTooltip v-if="(isMainButton && isExpanded) || (!isMainButton && !isExpanded)" :text="`${isExpanded ? 'Collapse' : 'Expand'} sidebar`">
    <UButton
      variant="ghost"
      color="gray"
      :ui="{
        color: {
          gray: {
            ghost:
              [
                'text-gray-700',
                'dark:text-gray-200',
                'hover:text-gray-900',
                'dark:hover:text-white',
                'hover:bg-gray-200',
                'dark:hover:bg-gray-800',
                'focus-visible:ring-inset',
                'focus-visible:ring-2',
                'focus-visible:ring-primary-500',
                'dark:focus-visible:ring-primary-400'
              ].join(' '),
          },
        },
        padding: {
          '2xs': 'p-1.5',
          xs: 'p-1.5',
          sm: 'p-1.5',
          md: 'p-1.5',
          lg: 'p-1.5',
          xl: 'p-1.5',
        },
      }"
      @click="toggleSidebar()"
    >
      <IconAside class="size-4" />
    </UButton>
  </UTooltip>
</template>

<script setup lang="ts">
defineProps({
  isMainButton: {
    type: Boolean,
    default: false,
  },
})
const { isExpanded, toggleSidebar } = useDashboardSidebar()
</script>
