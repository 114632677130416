export const useDashboardSidebar = () => {
  const isExpanded = useLocalStorage('is-sidebar-expanded', true)

  const toggleSidebar = (open?: boolean) => {
    isExpanded.value = open !== undefined ? open : !isExpanded.value
  }

  return {
    isExpanded,
    toggleSidebar,
  }
}
