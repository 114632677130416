<template>
  <svg
    width="24" height="24" viewBox="0 0 24 24" fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2058_69686)">
      <path
        fill-rule="evenodd" clip-rule="evenodd"
        d="M21 19.75C21.6904 19.75 22.25 19.1904 22.25 18.5L22.25 4.5C22.25 3.80964 21.6904 3.25 21 3.25L3 3.25C2.30965 3.25 1.75 3.80964 1.75 4.5L1.75 18.5C1.75 19.1904 2.30964 19.75 3 19.75L21 19.75ZM23.75 18.5C23.75 20.0188 22.5188 21.25 21 21.25L3 21.25C1.48122 21.25 0.25 20.0188 0.25 18.5L0.250001 4.5C0.250002 2.98121 1.48122 1.75 3 1.75L21 1.75C22.5188 1.75 23.75 2.98122 23.75 4.5L23.75 18.5Z"
        fill="#0F172A"
      />
      <path
        fill-rule="evenodd" clip-rule="evenodd"
        d="M9.75 21.25C9.33579 21.25 9 20.9142 9 20.5L9 2.5C9 2.08579 9.33579 1.75 9.75 1.75C10.1642 1.75 10.5 2.08579 10.5 2.5L10.5 20.5C10.5 20.9142 10.1642 21.25 9.75 21.25Z"
        fill="#0F172A"
      />
      <path
        fill-rule="evenodd" clip-rule="evenodd"
        d="M7 15.5C7 15.9142 6.71218 16.25 6.35714 16.25L4.64286 16.25C4.28782 16.25 4 15.9142 4 15.5C4 15.0858 4.28782 14.75 4.64286 14.75L6.35714 14.75C6.71218 14.75 7 15.0858 7 15.5Z"
        fill="#0F172A"
      />
      <path
        fill-rule="evenodd" clip-rule="evenodd"
        d="M7 11.5C7 11.9142 6.71218 12.25 6.35714 12.25L4.64286 12.25C4.28782 12.25 4 11.9142 4 11.5C4 11.0858 4.28782 10.75 4.64286 10.75L6.35714 10.75C6.71218 10.75 7 11.0858 7 11.5Z"
        fill="#0F172A"
      />
      <path
        fill-rule="evenodd" clip-rule="evenodd"
        d="M7 7.5C7 7.91421 6.71218 8.25 6.35714 8.25L4.64286 8.25C4.28782 8.25 4 7.91421 4 7.5C4 7.08579 4.28782 6.75 4.64286 6.75L6.35714 6.75C6.71218 6.75 7 7.08579 7 7.5Z"
        fill="#0F172A"
      />
    </g>
    <defs>
      <clipPath id="clip0_2058_69686">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
